<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M16.7273 8V31.2727H40V34.1818H34.1818V40H31.2727V34.1818H16.7273C15.12 34.1818 13.8182 32.88 13.8182 31.2727V16.7273H8V13.8182H13.8182V8H16.7273ZM34.1818 28.3632H31.2728V16.7268H19.6364V13.8177H31.2727C32.88 13.8177 34.1818 15.1196 34.1818 16.7268V28.3632Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-crop-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
